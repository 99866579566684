let isDev, isSandbox, isStaging, isProduction, isLocal;

if (typeof window !== "undefined") {
  isDev = window.location.hostname.includes("autodisco.us.mt.d.demystdata.com");
  isSandbox = window.location.hostname.includes("sbox.demyst.com");
  isStaging = window.location.hostname.includes("stg.demyst.com");
  isProduction =
    window.location.hostname === "demyst.com" || window.location.hostname === "app.demyst.com";
  isLocal = window.location.hostname.includes("localhost");
}

export const demystConsoleApiUrls = {
  local: "https://manta.local.mt.d.demystdata.com:8443",
  dev: "https://console-dev.demystdata.com",
  sandbox: "https://console-dev.demystdata.com",
  staging: "https://console-stg.demystdata.com",
  production: "https://console.demystdata.com",
  productionCloudfront: "/console",
};

export const blackfinApiUrls = {
  local: "https://blackfin.local.mt.d.demystdata.com:8443",
  dev: "https://blackfin-dev.us.mt.n.demystdata.com",
  sandbox: "https://blackfin-dev.us.mt.n.demystdata.com",
  staging: "https://blackfin-stg.us.mt.n.demystdata.com",
  production: "https://gw.us.demystdata.com",
};

export const getDemystConsoleApiUrl = () => {
  // --------- get manta_url to use server-side --------
  if (typeof window === "undefined") {
    return process.env.MANTA_HOST || "https://demyst.com/console";
  }

  // --------- get manta_url to use client-side --------
  else {
    return (
      window.localStorage.getItem("demystConsoleApiUrl") ||
      demystConsoleApiUrls.productionCloudfront
    );
  }
};

export const getBlackfinApiUrl = () => {
  if (typeof window === "undefined") return demystConsoleApiUrls.production;
  if (isStaging) return blackfinApiUrls.staging;

  const urlFromLS = window.localStorage.getItem("blackfinApiUrl");

  if (urlFromLS) {
    return urlFromLS;
  }

  return blackfinApiUrls.production;
};

const isDevOrSandbox = isDev || isSandbox;

export const appConfig = {
  // Generally, server address should be specified in `.env` file so it can be modified based on the environment
  // (dev, staging, prod etc.) and, as a result, be easily configurable for devops team, but since we don't have
  // any other environments it might as well just be in config file
  VERSION: "0.1",
  UNIVERSAL_ANALYTICS_ID: "UA-44063675-39",
  GA4_MEASURMENT_ID: "G-76XZ5L21DD",
  TAG_MANAGER_ID: "GTM-KDMXC83",
  IS_LOCAL: isLocal,
  IS_PRODUCTION: isProduction,
  IS_DEV: isDevOrSandbox,
  IS_STAGING: isStaging,
  USER_TRACKING_DEBUG: !isProduction,
  DEMYST_CONSOLE_API_URL: getDemystConsoleApiUrl(),
  BLACKFIN_API_URL: getBlackfinApiUrl(),
  MEDIUM_FETCH_URL:
    "https://api.rss2json.com/v1/api.json?api_key=ndhhtm8ojvto3drlelgrigldalef503s2t6u61wg&rss_url=https://medium.com/feed/@DemystData",
  CAPTCHA_KEY: "6Lcfo1oaAAAAAD8k7crktxEmPR0nOGq75UoX-kP4",
  // this key has to be pressed alongside "Ctrl"
  DEVTOOLS_SHORTCUT_KEY: "b",
  EXCLUDED_DOMAINS_AND_EMAILS: [
    "demystdata.com",
    "example.com",
    "test.com",
    "kliegman14@gmail.com",
  ],
  platformFee: function (user) {
    if (typeof user?.organization?.transaction_fee !== "number") {
      return 5; // default to present to any unathenticated accounts
    }
    return user.organization.transaction_fee;
  },
  generateToken: function (currDatetime) {
    const secret = "XTszmBq0z3Fk/KYYAKZWdA==";
    const secretX = secret.substring(0, 12);
    const secretY = secret.substring(12, 24);

    const timestamp = btoa(currDatetime);
    const timestampX = timestamp.substring(0, 8);
    const timestampY = timestamp.substring(8, 16);
    return btoa(timestampY + secretX + timestampX + secretY);
  },
};

export const cookiesConfig = {
  deprecated: [
    "_manta_content_variant_cta",
    "_manta_content_variant_cta-main",
    "_manta_content_variant_homepage",
    "_manta_content_variant_cta-001",
    "_manta_content_variant_homepage-001",
  ],
  // cta: {
  //   cookieName: "_manta_content_variant_cta-001",
  //   variants: ["cta-a-sign-up", "cta-b-access-data", "cta-c-get-started"],
  // },
  // homepage: {
  //   cookieName: "_manta_content_variant_homepage-001",
  //   variants: ["homepage-a-control", "homepage-b-ym-a", "homepage-c-ak-a"],
  // },
};

export const userPermissions = {
  canDownloadBatch: "can_download_batch",
  canDownloadDataDictionary: "can_download_data_dictionary",
  canDownloadSampleData: "can_download_sample_data",
  canManageCredentials: "can_manage_credentials",
  canManageOfflineBatches: "can_manage_offline_batches",
  canViewCategories: "can_view_categories",
  canShowPricing: "can_show_pricing",
  canShowDocumentation: "can_show_documentation",
  canShowAdmin: "can_show_admin",
  canShowFileTransfers: "can_show_file_transfers",
  canShowBilling: "billing_read",
  canShowGateway: "can_show_gateway",
  canShowReporting: "can_show_reporting",
  canUploadFile: "can_upload_file",
  demoUseCaseRead: "demo_use_case_read",
  canCreateUsers: "user_write",
};

export const userPlans = {
  LEAD: "lead",
  QUALIFIED_LEAD: "qualifiedlead",
  ENTERPRISE: "enterprise",
  UNVERIFIED: "unverified",
};

export const animatePresenceConfig = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { duration: 0.2 },
};

const validPasswordRegExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^0-9a-zA-Z])/;
const minimumPasswordLength = 8;

export function verifyPasswordValidity(password) {
  return validPasswordRegExp.test(password) && password.length >= minimumPasswordLength;
}
